import { Box, Flex } from '../../Design';
import { triangleDown } from './styles.css';

export interface ArrowDividerProps {}

const ArrowDownDivider = (props: ArrowDividerProps) => {
  const {} = props;
  return (
    <Flex
      justifyContent="center"
      position="absolute"
      height={{ mobile: '32px', tablet: '48px' }}
      bottom="0px"
      overflowX="hidden"
      width="full"
    >
      <Box backgroundColor="transparent" className={triangleDown} />
    </Flex>
  );
};

export default ArrowDownDivider;
