import { HomePageContentfulData } from '../../../pages/api/utils/contentful';
import { KnownProductTypes, specialRouteHrefs } from '../../../utils/const';
import { hexToRgbA } from '../../../utils/theme';
import { Box, BoxProps, Center, Flex, Stack, Text } from '../../Design';
import { ChevronRight } from '../../Design/Icons';
import { colors } from '../../Design/vars.css';
import ArrowDownDivider from '../../Dividers/ArrowDownDivider';
import InPageContainer from '../../Layout/InPageContainer';
import LineBreakHandler from '../../LineBreakHandler';
import LinkButton from '../../LinkButton';
import {
  homeHeroContainerClassName,
  homeHeroContentClassName,
  homeHeroSubheadingClassName,
} from './styles.css';

export interface HomeHeroProps
  extends Partial<
    Omit<
      HomePageContentfulData,
      'emailListFormHeading' | 'emailListFormSubheading' | 'newListingsHeading' | 'instagramHeading'
    >
  > {}

const HomeHero = ({
  __typename,
  desktopHeroImage,
  mobileHeroImage,
  mobileHeroColorOverlay,
  mobileHeroOverlayColorOpacity,
  desktopHeroColorOverlay,
  desktopHeroOverlayColorOpacity,
  addColorOverlayDesktop,
  addColorOverlayMobile,
  ...props
}: HomeHeroProps) => {
  // TODO: Figure out bg responsive images with vanilla

  return (
    <Box
      element="section"
      position="relative"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      minHeight="350px"
      maxHeight={{ mobile: '400px', desktop: '600px' }}
      className={homeHeroContainerClassName}
    >
      <InnerHeroContent
        display={{ mobile: 'block', mobileLg: 'none' }}
        height={mobileHeroImage?.height ?? undefined}
        width={mobileHeroImage?.width ?? undefined}
        bgImage={mobileHeroImage?.url ?? undefined}
        overlayColorOpacity={mobileHeroOverlayColorOpacity}
        overlayColor={mobileHeroColorOverlay?.value}
        ignoreHeroOverlay={!addColorOverlayMobile}
        {...props}
      />
      <InnerHeroContent
        display={{ mobile: 'none', mobileLg: 'block' }}
        height={desktopHeroImage?.height ?? undefined}
        width={desktopHeroImage?.width ?? undefined}
        bgImage={desktopHeroImage?.url ?? undefined}
        overlayColorOpacity={desktopHeroOverlayColorOpacity}
        overlayColor={desktopHeroColorOverlay?.value}
        ignoreHeroOverlay={!addColorOverlayDesktop}
        {...props}
      />
      <ArrowDownDivider />
    </Box>
  );
};

export interface InnerHeroContentProps
  extends Omit<
    HomeHeroProps,
    | 'desktopHeroImage'
    | 'mobileHeroImage'
    | 'mobileHeroColorOverlay'
    | 'mobileHeroOverlayColorOpacity'
    | 'desktopHeroColorOverlay'
    | 'desktopHeroOverlayColorOpacity'
    | 'addColorOverlayDesktop'
    | 'addColorOverlayMobile'
  > {
  display: BoxProps<'div'>['display'];
  overlayColorOpacity?: number;
  overlayColor?: string;
  bgImage?: string;
  ignoreHeroOverlay?: boolean;
  width?: number;
  height?: number;
}

export const InnerHeroContent = ({
  heroHeading,
  heroSubheading,
  heroButtonText,
  overlayColorOpacity,
  overlayColor,
  display,
  bgImage,
  ignoreHeroOverlay,
}: InnerHeroContentProps) => {
  const calculatedOverlayColor = hexToRgbA(overlayColor ?? colors.white, overlayColorOpacity ?? 0);

  return (
    <Flex
      height="full"
      width="full"
      position="relative"
      justifyContent="space-between"
      marginX="auto"
      overflow="hidden"
      display={display}
      className={homeHeroContentClassName}
    >
      {bgImage && (
        <Box
          element="img"
          width="full"
          height="full"
          position="absolute"
          zIndex="hide"
          alt=""
          aria-hidden="true"
          src={bgImage}
          style={{
            objectFit: 'cover',
            objectPosition: '100% center',
            // height: resized?.height.toFixed(2),
            // width: resized?.width.toFixed(2)
          }}
        />
      )}
      {!ignoreHeroOverlay && calculatedOverlayColor && (
        <Box
          element="div"
          width="full"
          height="full"
          position="absolute"
          zIndex="hide"
          aria-hidden="true"
          style={{
            objectFit: 'cover',
            objectPosition: '100% center',
            backgroundColor: calculatedOverlayColor ?? undefined,
          }}
        />
      )}
      <InPageContainer height="full">
        <Center width={{ mobile: 'full', mobileLg: 'half', tablet: 'half' }} height="full">
          <Stack spacing="20px" marginBottom={{ mobile: '32px', tablet: '48px' }} maxWidth="600px">
            <Box>
              <Text
                element="h1"
                // variant="unstyled"
                fontSize={{ mobile: 'xxl', tablet: 'xxxl' }}
                color="primary"
                textAlign={{ mobile: 'center', mobileLg: 'left' }}
              >
                <LineBreakHandler string={heroHeading ?? `Beautiful Orchids At A Great Price!`} />
              </Text>
              <Text
                element="h2"
                fontSize={{ mobile: 'md', desktop: 'lg' }}
                className={homeHeroSubheadingClassName}
                textAlign={{ mobile: 'center', mobileLg: 'left' }}
              >
                <LineBreakHandler
                  string={
                    heroSubheading ??
                    'Orchids Delivered Straight To Your Door ~<br> Including Cattleya, Oncidium, Dendrobium & More!'
                  }
                />
              </Text>
            </Box>
            <Flex justifyContent={{ mobile: 'center', mobileLg: 'flex-start', tablet: 'flex-end' }}>
              <LinkButton
                href={specialRouteHrefs?.plp({ categoryType: KnownProductTypes.orchid })}
                buttonProps={{ variant: 'tertiary', minWidth: '150px' }}
                includeLoader
              >
                <Text element="span" display="flex" flexDirection="row" alignItems="center">
                  {heroButtonText ?? 'Start Shopping'}
                </Text>
                <ChevronRight style={{ width: '24px', height: '24px' }} />
              </LinkButton>
            </Flex>
          </Stack>
        </Center>
      </InPageContainer>
    </Flex>
  );
};

export default HomeHero;
