import { useProductStore } from '../../store/rootStoreProvider';
import { cardHover, cardHoverGrowWidth, skeleton } from '../../styles/index.css';
import { ProductData } from '../../types/store';
import { specialRouteHrefs } from '../../utils/const';
import { Box, ButtonProps, Flex, Text } from '../Design';
import { Sprinkles } from '../Design/sprinkles.css';
import LinkButton from '../LinkButton';
import Price from '../Price';

export interface ProductCardProps extends ButtonProps<'a'> {
  data?: ProductData;
  disableCardHoverGrowth?: boolean;
}

const ProductCard = ({
  data,
  boxShadow,
  disableCardHoverGrowth,
  isLoading,
  ...props
}: ProductCardProps) => {
  const { featuredImage, title, price, handle, totalInventory } = data ?? {};
  const { productsByHandle, loading: productStoreLoading } = useProductStore();
  const productExistsInStore = !!handle && !!productsByHandle?.[handle];
  const loading = isLoading ?? productStoreLoading;

  return (
    <LinkButton
      disableLinkUnderlined
      href={specialRouteHrefs.pdp({
        handle: handle ?? '',
        productExistsInStore: productExistsInStore ?? false,
        // variantID: data?.variants?.[0]?.id
      })}
      buttonProps={{
        flex: '1',
        variant: 'unstyled',
        borderRadius: 'base',
        overflow: 'hidden',
        boxShadow: boxShadow ?? 'base',
        height: 'full',
        width: 'full',
        className: [cardHover, disableCardHoverGrowth ? '' : cardHoverGrowWidth].join(' '),
        overrideDefaultLoader: true,
        isLoading: loading,
        ...props,
      }}
      // asLinkOnly
      asLinkOnlyNoHover
    >
      <Box
        element="span"
        display="flex"
        flexDirection="column"
        width="full"
        height="full"
        position="relative"
      >
        {(price || totalInventory) && (
          <Box
            element="span"
            position="absolute"
            top="0px"
            marginTop="8px"
            marginRight="4px"
            right="0px"
          >
            <ProductBadge {...{ price, totalInventory, productTitle: title }} />
          </Box>
        )}
        <Box
          element="span"
          display="flex"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          backgroundColor="gray"
          style={{ aspectRatio: '1 / 1' }}
        >
          {!loading ? (
            <Box
              element="span"
              flex="1"
              height="full"
              style={{
                backgroundImage: `url(${featuredImage?.url ?? '/assets/Image-coming-soon.png'})`,
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
              aria-hidden="true"
            />
          ) : (
            <Box element="span" flex="1" height="full" aria-hidden="true" className={skeleton} />
          )}
        </Box>
        <Flex
          element="span"
          flex="1"
          width="full"
          flexDirection="column"
          padding={{ mobile: '12px', tablet: '16px' }}
        >
          <Text
            element="span"
            flex="1"
            variant="cardHeading"
            whiteSpace="normal"
            width="full"
            minHeight="24px"
            height="auto"
            textTransform="none"
            className={loading ? skeleton : undefined}
          >
            {loading ? '' : title}
          </Text>
          <Flex
            element="span"
            width="full"
            marginTop={{ mobile: '12px', tablet: '20px' }}
            justifyContent={{ mobile: 'flex-end', desktop: 'flex-end' }}
          >
            {loading ? (
              <Box
                element="span"
                minWidth="48px"
                minHeight="32px"
                className={skeleton}
                style={{ content: '""' }}
              />
            ) : (
              <Flex element="span" flexDirection="row">
                <Price
                  isSpan
                  price={price}
                  enablePlusIndicator={data?.variants && data.variants.length > 1}
                />
              </Flex>
            )}
          </Flex>
        </Flex>
      </Box>
    </LinkButton>
  );
};

export type ProductBadgeOptions = 'sale' | 'out of stock';

export interface ProductBadgeProps extends Pick<ProductData, 'totalInventory' | 'price'> {
  productTitle?: ProductData['title'];
}

export const ProductBadge = ({ totalInventory, price, productTitle }: ProductBadgeProps) => {
  const isGiftCard = productTitle?.toLocaleLowerCase()?.includes('gift card');
  const badgeDecider = (() => {
    if (totalInventory === 0) return 'out of stock';
    if (price?.discounted) return 'sale';
  })();

  const badgeDetails = (() => {
    switch (badgeDecider) {
      case 'sale':
        return {
          text: 'On Sale',
          color: 'success',
        };
      case 'out of stock':
        if (isGiftCard) return null;

        return {
          text: 'Out Of Stock',
          color: 'error',
        };

      default:
        return null;
    }
  })();

  if (!badgeDetails) return null;

  return (
    <Text
      element="span"
      backgroundColor={badgeDetails.color as Sprinkles['backgroundColor']}
      textTransform="capitalize"
      padding="8px"
      color="white"
      zIndex="popover"
      borderRadius="medium"
    >
      {badgeDetails.text}
    </Text>
  );
};

export default ProductCard;
