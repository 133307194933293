import { Fragment } from 'react';

import { Box } from './Design';

export interface LineBreakHandlerProps {
  string: string;
}

const LineBreakHandler = ({ string }: LineBreakHandlerProps) => {
  const breakStringOnBr = string?.split('<br>');

  if (breakStringOnBr?.length <= 1) {
    return <>{string}</>;
  }

  return (
    <>
      {breakStringOnBr?.map((str, index) => {
        if (index === breakStringOnBr?.length) {
          return <Fragment key={str + index}> {string} </Fragment>;
        }

        return (
          <Fragment key={str + index}>
            {str}
            <Box key={str + index} element="br" display={{ mobile: 'none', tablet: 'block' }} />
          </Fragment>
        );
      })}
    </>
  );
};

export default LineBreakHandler;
