import * as React from 'react';

import { ProductData } from '../types/store';
import { Box, Stack, Text } from './Design';
import { Sprinkles } from './Design/sprinkles.css';

interface PriceProps extends Omit<React.ComponentProps<typeof Text>, 'fontSize' | 'size'> {
  price?: ProductData['price'];
  size?: {
    dollar: Sprinkles['fontSize'];
    cents: Sprinkles['fontSize'];
  };
  strikeSize?: {
    dollar: Sprinkles['fontSize'];
    cents: Sprinkles['fontSize'];
  };
  centProps?: React.ComponentProps<typeof Box>;
  enablePlusIndicator?: boolean;
  isSpan?: boolean;
}

const Price = ({
  price,
  size = {
    dollar: { mobile: 'xl', desktop: 'xxl' },
    cents: { mobile: 'md', desktop: 'lg' },
  },
  strikeSize = {
    dollar: { mobile: 'sm', desktop: 'md' },
    cents: { mobile: 'xxs', desktop: 'xs' },
  },
  color,
  style,
  centProps,
  enablePlusIndicator,
  isSpan,
  ...props
}: PriceProps) => {
  if (!price) return null;

  const dollarsAndCents = price?.base && `${(+price?.base).toFixed(2)}`?.split('.');
  const discountedDollarsAndCents =
    price?.discounted && `${(+price?.discounted).toFixed(2)}`?.split('.');
  const hasDiscountedPrice = !!price?.discounted;

  return (
    <Stack element={isSpan ? 'span' : 'div'} direction="row" alignItems="flex-end">
      {hasDiscountedPrice && (
        <Box
          element={isSpan ? 'span' : 'p'}
          fontSize={strikeSize?.dollar}
          display="flex"
          alignItems="flex-start"
          color="error"
          textDecoration="line-through"
          style={{ lineHeight: '1rem', paddingTop: '0.25em', ...style }}
          {...props}
        >
          ${dollarsAndCents?.[0]}
          <Box element="span" visibility="hidden" width="2px">
            .
          </Box>
          <Box
            element="span"
            fontSize={strikeSize?.cents}
            color="inherit"
            position="relative"
            style={{ bottom: '0.25em' }}
            {...centProps}
          >
            {dollarsAndCents?.[1]}
          </Box>
        </Box>
      )}
      <Box
        element={isSpan ? 'span' : 'p'}
        fontSize={size?.dollar}
        display="flex"
        alignItems="flex-start"
        color={color ?? 'primary'}
        style={{ lineHeight: '1rem', paddingTop: '0.25em', ...style }}
        {...props}
      >
        ${!hasDiscountedPrice ? dollarsAndCents?.[0] : discountedDollarsAndCents?.[0]}
        <Box element="span" visibility="hidden" width="2px">
          .
        </Box>
        <Box
          element="span"
          fontSize={size?.cents}
          color="inherit"
          position="relative"
          style={{ bottom: '0.25em' }}
          {...centProps}
        >
          {!hasDiscountedPrice ? dollarsAndCents?.[1] : discountedDollarsAndCents?.[1]}{' '}
          {enablePlusIndicator && <span>+</span>}
        </Box>
      </Box>
    </Stack>
  );
};

export default Price;
